import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-start -mx-2" }
const _hoisted_2 = ["onClick", "data-original-title"]
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-gray-800 font-semibold" }
const _hoisted_6 = { class: "text-gray-600 text-sm" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "w-1/4 h-150 sm:w-1/3 md:w-1/4 lg:w-1/5 p-2 mb-4",
        key: idx
      }, [
        _createElementVNode("div", {
          class: "w-full h-full bg-white border border-gray-200 p-4 rounded-lg shadow-md cursor-pointer transition hover:shadow-lg",
          onClick: $event => ($options.openweb(item.url)),
          "data-toggle": "tooltip",
          "data-placement": "bottom",
          "data-original-title": item.url
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: item.logo,
              alt: "",
              class: "w-12 h-12 rounded-full object-cover"
            }, null, 8 /* PROPS */, _hoisted_4),
            _createElementVNode("div", null, [
              _createElementVNode("h4", _hoisted_5, _toDisplayString(item.title), 1 /* TEXT */),
              _createElementVNode("p", _hoisted_6, _toDisplayString(item.desc), 1 /* TEXT */)
            ])
          ])
        ], 8 /* PROPS */, _hoisted_2)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}