<template>
  <div class="flex flex-wrap justify-start -mx-2">
    <div
      class="w-1/4 h-150 sm:w-1/3 md:w-1/4 lg:w-1/5 p-2 mb-4"
      v-for="(item, idx) in items"
      :key="idx"
    >
      <div
        class="w-full h-full bg-white border border-gray-200 p-4 rounded-lg shadow-md cursor-pointer transition hover:shadow-lg"
        @click="openweb(item.url)"
        data-toggle="tooltip"
        data-placement="bottom"
        :data-original-title="item.url"
      >
        <div class="flex items-center space-x-4">
          <img
            :src="item.logo"
            alt=""
            class="w-12 h-12 rounded-full object-cover"
          />
          <div>
            <h4 class="text-gray-800 font-semibold">{{ item.title }}</h4>
            <p class="text-gray-600 text-sm">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebItem',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    openweb(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
</style>
