export const organization = {
  orgSetting: "组织设置",
  orgInfo: "组织信息",
  orgTeam: "团队成员",
  orgNameSpace: "组织命名空间",
  orgSpaceTips: "创建完成后，命名空间不可更改",
  orgNickName: "组织别名",
  orgAvatar: "组织头像",
  orgType: "组织类型",
  orgHomepage: "组织主页",
  model: "模型",
  dataset: "数据集",
  space: "应用空间",
  code: "代码仓库",
  orgMembers: "组织成员",
  create:"创建",
  orgIndustry: "组织行业",
  orgDescription: "组织介绍",
  members: {
    title: "成员列表",
    description: "管理您的组织成员及其账户权限",
    last_login_at: "上次登录时间",
    role: "角色",
    username: "用户名",
    editRole: "编辑角色",
    confirm: "确定",
    cancel: "取消",
    deleteSuccess: "成员移除成功",
    editRoleSuccess: "成员更新成功",
    deleteConfirmTitle: `是否确定将用户 {username} 移出组织 {orgName}`,
  },
  invite: {
    title: "邀请",
    inviteNew: "邀请新成员",
    inviteDesc: "你正在邀请新成员到组织",
    addSuccess: "添加成员成功",
  },
  newOrganization: {
    title: "新建组织",
    createOrg: "创建组织",
    createSuccess: "组织创建成功",
    inputSpaceNamePls: "请填写组织命名空间",
  },
  edit: {
    role:"修改用户角色",
    roleTips:"通过访问文档来了解不同角色的权限区别",
    updateSuccess: "organization已更新",
  },
  collection: {
    industry: "行业",
    org_type: '组织类型',
    search_placeholder: "搜索组织名称",
    create_organization: "新建组织",
  }
}