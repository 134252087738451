import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: $setup.activeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.activeName) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: `${$setup.resourceName()}卡片`,
          name: "summary"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "summary")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_tab_pane, {
          label: "文件和版本",
          name: "files"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "files")
          ]),
          _: 3 /* FORWARDED */
        }),
        _createVNode(_component_el_tab_pane, {
          label: "讨论",
          name: "community",
          class: "min-h-[300px]"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "community")
          ]),
          _: 3 /* FORWARDED */
        }),
        ($props.settingsVisibility)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: "设置",
              name: "settings",
              class: "min-h-[300px]"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "settings")
              ]),
              _: 3 /* FORWARDED */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}