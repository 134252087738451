<template>
  <teleport to="body">
    <el-dialog
      v-if="admin"
      v-model="dialogVisible"
      title="邀请新成员"
      width="350"
      :append-to-body="true"
    >
      <div>
        <span>你正在邀请新成员到组织 <span class="font-bold">{{ orgName }}</span></span>
        <div class="mt-[20px]">
          <div class="mb-[20px]">
            <p class="text-[#344054] text-[14px] mb-[6px]">用户名</p>
            <div class="flex gap-[4px] items-center w-full border rounded-[4px] border-gray-300 h-[40px] p-[6px]">
              <div class="flex gap-[4px]">
                <span v-for="user in selectedUsers" class="flex items-center gap-[5px] border rounded-[5px] border-gray-300 px-[5px] py-[2px]">
                  <img :src="user.avatar" height="16" width="16">
                  {{ user.name }}
                  <el-icon><Close @click="removeUser(user.name)" /></el-icon>
                </span>
              </div>
              <input class="w-full h-[36px] outline-none"
                     v-model="userNameInput"
                     @input="showUserList" />
            </div>
            <div v-show="shouldShowUserList" class="rounded-md border border-gray-200 bg-white shadow-lg py-[4px] px-[6px]">
              <p v-for="user in userList"
                 @click="selectUser(user)"
                 class="flex gap-[8px] items-center cursor-pointer p-[10px]"
              >
                <img :src="user.avatar" height="16" width="16">
                {{ user.name }}
              </p>
            </div>
          </div>
          <div>
            <p class="text-[#344054] text-[14px] mb-[6px]">角色</p>
            <el-select v-model="userRoleInput" placeholder="选择角色" class="w-full">
              <el-option
                v-for="item in roleMappings"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmInviteNewMember">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </teleport>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'
  import csrfFetch from "../../../../packs/csrfFetch.js"
  import { ElMessage } from 'element-plus'

  const emit = defineEmits(['resetMemberList'])

  const props = defineProps({
    orgName: String,
    admin: Boolean
  })

  const dialogVisible = ref(false)
  const userNameInput = ref('')
  const userRoleInput = ref('read')
  const selectedUsers = ref([])
  const userList = ref([])
  const shouldShowUserList = ref(false)
  const roleMappings = [
    {
      value: 'read',
      label: 'read'
    },
    {
      value: 'write',
      label: 'write'
    },
    {
      value: 'admin',
      label: 'admin'
    }
  ]

  const removeUser = (username) => {
    selectedUsers.value = selectedUsers.value.filter( item => item.name !== username )
  }

  const selectUser = (newUser) => {
    const findUser = selectedUsers.value.find(user => user.name === newUser.name)
    if (!findUser) {
      selectedUsers.value.push({name: newUser.name, avatar: newUser.avatar})
    }
  }

  const showUserList = (e) => {
    getUsers(e.data).then(data => {
      shouldShowUserList.value = data.users.length > 0
      userList.value = data.users
    })
    .catch(err => {
      ElMessage({
        message: err.message,
        type: 'warning'
      })
    })
  }

  async function getUsers(username) {
    const usersEndpoint = `/internal_api/users?name=${username}`;
    const options = {
      method:'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }
    const response = await csrfFetch(usersEndpoint, options)
    if (!response.ok) {
      return response.json().then(data => { throw new Error(data.message) })
    } else {
      return response.json();
    }
  }

  const confirmInviteNewMember = () => {
    inviteNewMember().then(() => {
      emit('resetMemberList', selectedUsers.value, userRoleInput.value)
      dialogVisible.value = false
      ElMessage({
        message: '添加成员成功',
        type: 'success'
      })
    })
    .catch(err => {
      ElMessage({
        message: err.message,
        type: 'warning'
      })
    })
  }

  async function inviteNewMember() {
    const inviteNewMemberEndpoint = '/internal_api/organizations/new-members';
    const options = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        org_name: props.orgName,
        user_names: selectedUsers.value.map(user => user.name).join(','),
        user_role: userRoleInput.value
      })
    }
    const response = await csrfFetch(inviteNewMemberEndpoint, options)
    if (!response.ok) {
      return response.json().then(data => { throw new Error(data.message) })
    } else {
      return response.json();
    }
  }

  const openDialog = () => {
    dialogVisible.value = true
  }

  defineExpose({
    openDialog
  })
</script>

<style scoped>
:deep(.el-dialog) {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>