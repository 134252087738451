export const models = {
  title: "Model",
  placeholder: "Models name",
  downloadModel: "Download model data",
  download: "Download Model",
  modelName: "Model name",
  datasetNameTips:
    "Applied to the model path, cannot be changed after creation",
  namespaceModelName: "Name space/Model name",
  modelNickName: "Model alias",
  modelDesc: "Model Profile",
  modelTag: "Model Tags",
  tips:" Creating and Uploading Model Files",
  newModel: {
    title: "Create a new model repository",
    titleDesc: "The repository contains all model files and revision history",
    owner: "Owner",
    modelName: "Model Name",
    modelNickName: "Model Nickname",
    modelDesc: "Model Description",
    public: "Public",
    publicDesc:
      "Anyone on the internet can see this model. Only you (personal model) or members of your organization can commit.",
    private: "Private",
    privateDesc:
      "Only you (personal model) or members of your organization can see and commit to this model.",
    tips: "Once your model is created, you can upload your files using the web interface or git.",
    createModel: "Create",
    createSuccess: "Model created successfully",
    tip: "Applies to model data and cannot be changed after creation" 
  },
  edit: {
    tips: "Will be displayed on the model list page, optional",
    tips2: "An introduction about the model, optional",
    tips3: "Choose classification labels corresponding to the model to facilitate users in quickly finding your model during filtering.",
    changeVisibility: "Change model visibility",
    statusText: "The current model is now",
    status: "Status",
    modelVisibility: "Model visibility",
    delModel: "Delete model",
    delTips: "This action",
    delTips2: "can be undone and will permanently delete",
    delTips3: "the model repository and all its files.",
    privateVis: "Only creator or members of your organization can see",
    publicVis: "Anyone can see this model",
    confirmDel: "I understand, delete this model",
    privateInfo:
      ", only the creator or organization admin can make submissions",
    publicInfo: ", visible to anyone on the internet.",
    needModelName: "Please provide a model nickname first",
    needModelDesc: "Please provide a model description first",
    needModelTag: "Please provide a model tag first",
  },
}