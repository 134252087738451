import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[16px]" }
const _hoisted_2 = { class: "flex justify-between" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EmptyCommunity = _resolveComponent("EmptyCommunity")
  const _component_DiscussionDetails = _resolveComponent("DiscussionDetails")
  const _component_DiscussionCard = _resolveComponent("DiscussionCard")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = $event => ($data.showDetail?$data.showDetail=!$data.showDetail:$options.changeFlag('new'))),
        class: "px-[16px] py-[5px] border bg-[#FAFCFF] rounded-[4px] hover:bg-[#EBEDF0] text-[#606266] text-[14px] leading-[22px] cursor-pointer"
      }, _toDisplayString($data.showDetail?'回到话题列表':'新建话题'), 1 /* TEXT */),
      _createCommentVNode(" <div class=\"hover:bg-[#F5F7FA] cursor-pointer flex items-center gap-[8px] px-[16px] py-[5px] rounded-[4px] text-[#606266] text-[14px] leading-[22px]\">\n          <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" fill=\"none\"><path d=\"M5.54175 7.87533L7.00008 6.41699M7.00008 6.41699L8.45841 4.95866M7.00008 6.41699L5.54175 4.95866M7.00008 6.41699L8.45841 7.87533M10.918 12.2678V12.2678C11.761 12.7735 12.8334 12.1663 12.8334 11.1833V7.00032C12.8334 5.29289 12.8334 4.43917 12.577 3.75799C12.1713 2.67993 11.3205 1.82912 10.2424 1.42337C9.56124 1.16699 8.70752 1.16699 7.00008 1.16699H6.41675C5.25451 1.16699 4.6734 1.16699 4.19483 1.28687C2.76313 1.64549 1.64525 2.76337 1.28662 4.19507C1.16675 4.67364 1.16675 5.25476 1.16675 6.41699V6.41699C1.16675 7.57923 1.16675 8.16034 1.28662 8.63891C1.64525 10.0706 2.76313 11.1885 4.19483 11.5471C4.6734 11.667 5.25451 11.667 6.41675 11.667H8.74907C9.51314 11.667 10.2628 11.8746 10.918 12.2678Z\" stroke=\"#606266\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>\n          View closed（1）\n        </div> ")
    ]),
    ($props.cards.length <= 0)
      ? (_openBlock(), _createBlock(_component_EmptyCommunity, {
          key: 0,
          onChangeFlag: $options.changeFlag
        }, null, 8 /* PROPS */, ["onChangeFlag"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          ($data.showDetail)
            ? (_openBlock(), _createBlock(_component_DiscussionDetails, {
                key: 0,
                discussionId: $data.currentDiscussion,
                title: _ctx.currentTitle,
                userName: _ctx.currentUserName,
                createUserId: _ctx.createUserId,
                time: _ctx.currentTime,
                onGetDiscussion: $options.getDiscussion,
                onToggleDetails: $options.toggleDetails
              }, null, 8 /* PROPS */, ["discussionId", "title", "userName", "createUserId", "time", "onGetDiscussion", "onToggleDetails"]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.cards, (card) => {
                return (_openBlock(), _createBlock(_component_DiscussionCard, {
                  onClick: $event => ($options.showDetails(card)),
                  key: card.id,
                  discussionId: card.id,
                  num: card.num,
                  title: card.title,
                  time: card.time,
                  user: card.user.name,
                  commentNum: card.commentNum
                }, null, 8 /* PROPS */, ["onClick", "discussionId", "num", "title", "time", "user", "commentNum"]))
              }), 128 /* KEYED_FRAGMENT */))
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}