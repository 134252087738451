import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb736861"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex items-center mb-[5px] w-[399px] lg:w-[370px] mlg:w-full" }
const _hoisted_3 = {
  key: 0,
  class: "w-[390px] lg:w-[370px] mlg:w-full h-[35px] leading-[18px] mb-[5px] text-[#909399] text-xs overflow-hidden text-ellipsis line-clamp-2 text-left"
}
const _hoisted_4 = { class: "flex flex-nowrap overflow-hidden text-ellipsis items-center gap-[8px] text-xs text-[#909399]" }
const _hoisted_5 = {
  key: 0,
  class: "max-w-[80px] xl:max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("a", {
    href: $setup.detailLink,
    class: _normalizeClass([`${$props.repoType}-card  hover:active-${$props.repoType}-card `, "focus:outline focus:outline-4 focus:outline-[#EAECF0] hover:shadow-md p-4 mlg:w-full border border-gray-200 rounded-xl"])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([`${$props.repoType}-path`, "text-sm text-[#303133] font-medium text-ellipsis overflow-hidden whitespace-nowrap"])
      }, _toDisplayString($setup.getComputed.path), 3 /* TEXT, CLASS */)
    ]),
    ($setup.getComputed.showDescription)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.repo.description), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_4, [
      ($setup.getComputed.taskTag)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($setup.getComputed.taskTag), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($setup.getComputed.taskTag)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
            _createVNode(_component_SvgIcon, { name: "vertical_divider" })
          ]))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["ShortInfo"], {
        supplier: $props.repo.path.split('/')[0],
        updated_at: $props.repo.updated_at,
        downloads: $props.repo.downloads
      }, null, 8 /* PROPS */, ["supplier", "updated_at", "downloads"]),
      _createCommentVNode(" <div class=\"overflow-hidden text-ellipsis whitespace-nowrap\">\n        {{$t('all.lastTime')}}：{{ repo.updated_at.substring(0, 10) }}\n      </div>\n\n      <div class=\"overflow-hidden text-ellipsis whitespace-nowrap\">\n        {{$t('all.lastTime')}}：{{ repo.updated_at.substring(0, 10) }}\n      </div>\n\n      <span> <SvgIcon name=\"vertical_divider\" /> </span>\n\n      <span class=\"whitespace-nowrap\">{{ getComputed.visibility }}</span>\n\n      <span> <SvgIcon name=\"vertical_divider\" /> </span>\n\n      <span class=\"whitespace-nowrap\">{{$t('all.downloadCount')}}：{{ repo.downloads }}</span> ")
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}