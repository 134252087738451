import "trix"
import "@rails/actiontext"

// Vue config
import { createApp } from "vue/dist/vue.esm-bundler.js"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import { useCookies } from "vue3-cookies"
import { createI18n } from 'vue-i18n'
import en from '../../config/locales/en.js'
import zh from '../../config/locales/zh.js'


import XtyHome from "./components/xty_community/frontend/home/Index.vue"
import CommunityFrontendHeader from "./components/xty_community/frontend/header.vue"
import RankConfigurationIndex from "./components/xty_community/frontend/rank_configuration/index.vue"
import XtyNewModel from "./components/xty_community/frontend/models/NewModel.vue"
import XtyModelCards from "./components/xty_community/frontend/models/ModelCards.vue"
import XtyModelItem from "./components/xty_community/frontend/models/ModelItem.vue"
import XtyModelDetail from "./components/xty_community/frontend/models/ModelDetail.vue"
import XtyNewDataset from "./components/xty_community/frontend/datasets/NewDataset.vue"
import XtyDatasetCards from "./components/xty_community/frontend/datasets/DatasetCards.vue"
import XtyDatasetItem from "./components/xty_community/frontend/datasets/DatasetItem.vue"
import XtyDatasetDetail from "./components/xty_community/frontend/datasets/DatasetDetail.vue"
import XtyProfile from "./components/xty_community/frontend/user_settings/Profile.vue"
import XtyProfileSettings from "./components/xty_community/frontend/user_settings/ProfileSettings.vue"
import XtyProfileRepoList from "./components/xty_community/frontend/shared/ProfileRepoList.vue"
import XtyGitTokenSettings from "./components/xty_community/frontend/user_settings/GitTokenSettings.vue"
import XtySshKeySettings from "./components/xty_community/frontend/user_settings/SshKeySettings.vue"
import XtyNewOrganization from "./components/xty_community/frontend/organizations/NewOrganization.vue"
import XtyOrganizationDetail from "./components/xty_community/frontend/organizations/OrganizationDetail.vue"
import XtyOrganizationSettings from "./components/xty_community/frontend/organizations/OrganizationSettings.vue"
import XtyOrganizationCollection from "./components/xty_community/frontend/organizations/Collection.vue"
import XtySpaceIndex from "./components/xty_community/frontend/spaces/Index.vue"
import XtyDailyPaperNew from "./components/xty_community/frontend/daily_paper/New.vue"
import XtyDailyPaperIndex from "./components/xty_community/frontend/daily_paper/Index.vue"
import XtyDailyPaperShow from "./components/xty_community/frontend/daily_paper/Show.vue"
import XtySalonIndex from "./components/xty_community/frontend/salon/Index.vue"
import XtyBulletinShow from "./components/xty_community/frontend/bulletin/Show.vue"
import XtyUploadFiles from "./components/xty_community/frontend/uploads/Index.vue"
import XtyAiIssueNew from "./components/xty_community/frontend/ai_issue/New.vue"
import XtyDigitalIssueNew from "./components/xty_community/frontend/digital_issue/New.vue"
import XtyWebnav from "./components/xty_community/frontend/home/WebNav.vue"


app = createApp({
  components: {
    XtyHome,
    CommunityFrontendHeader,
    RankConfigurationIndex,
    XtyNewModel,
    XtyModelCards,
    XtyModelItem,
    XtyModelDetail,
    XtyNewDataset,
    XtyDatasetCards,
    XtyDatasetItem,
    XtyDatasetDetail,
    XtyProfile,
    XtyProfileSettings,
    XtyProfileRepoList,
    XtyGitTokenSettings,
    XtySshKeySettings,
    XtyNewOrganization,
    XtyOrganizationDetail,
    XtyOrganizationSettings,
    XtySpaceIndex,
    XtyDailyPaperNew,
    XtyDailyPaperIndex,
    XtyDailyPaperShow,
    XtyOrganizationCollection,
    XtySalonIndex,
    XtyBulletinShow,
    XtyUploadFiles,
    XtyAiIssueNew,
    XtyDigitalIssueNew,
    XtyWebnav,
  },
  provide:{
  }
}).use(ElementPlus, {
  locale: zhCn,
});

// register Element UI Icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
const { cookies } = useCookies()
const defaultLanguage = ['zh', 'zh-cn', 'zh-tw'].includes(navigator.language.toLowerCase()) ? 'zh' : 'en';

const i18n = createI18n({
  legacy: false,
  locale: cookies.get('locale') || defaultLanguage,
  messages: {
    en,
    zh
  }
})

app.use(i18n)
app.mount("#community-app")
