import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gradient-to-l from-[#FAFAFA] to-white" }
const _hoisted_2 = { class: "mx-auto max-w-[1440px] pt-[16px] px-[80px] pb-[40px] md:p-[16px]" }
const _hoisted_3 = { class: "mt-[10px]" }
const _hoisted_4 = { class: "mx-auto max-w-[1440px] px-[80px] mt-[-40px] md:px-[16px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["Breadcrumb"], {
          referActionUrl: "/models",
          referActionName: "模型列表",
          currentActionName: "模型详情"
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["ResourceHeader"], {
            private: $props.model.data.private,
            license: $props.model.data.license,
            name: $props.model.data.name,
            nickname: $props.model.data.nickname,
            desc: $props.model.data.description,
            path: $props.model.data.path,
            tags: $props.tags,
            avatar: $props.avatar,
            resource: $props.model.data,
            "owner-url": $props.ownerUrl
          }, null, 8 /* PROPS */, ["private", "license", "name", "nickname", "desc", "path", "tags", "avatar", "resource", "owner-url"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["ModelTabs"], {
        blob: $props.blob.data,
        "local-model-id": $props.localModelId,
        "local-model": $props.localModel,
        "model-path": $props.model.data.path,
        "model-nickname": $props.localModel.nickname,
        "model-desc": $props.localModel.desc,
        "model-default-branch": $props.model.data.default_branch,
        "model-private": $props.model.data.private,
        "model-detail": $props.model.data,
        "last-commit": $props.lastCommit.data,
        branches: $props.branches.data,
        "current-branch": $props.currentBranch,
        "current-path": $props.currentPath,
        "default-tab": $props.defaultTab,
        actionName: $props.actionName,
        settingsVisibility: $props.settingsVisibility,
        "can-write": $props.canWrite
      }, null, 8 /* PROPS */, ["blob", "local-model-id", "local-model", "model-path", "model-nickname", "model-desc", "model-default-branch", "model-private", "model-detail", "last-commit", "branches", "current-branch", "current-path", "default-tab", "actionName", "settingsVisibility", "can-write"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}