import { createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ResourceClone"], {
      resource: "model",
      "bt-link": $props.localModel.bt_link,
      "http-clone-url": $props.modelDetail.repository.http_clone_url,
      "ssh-clone-url": $props.modelDetail.repository.ssh_clone_url
    }, null, 8 /* PROPS */, ["bt-link", "http-clone-url", "ssh-clone-url"]),
    _createVNode($setup["TabContainer"], {
      resource: "model",
      "default-tab": $props.defaultTab,
      settingsVisibility: $props.settingsVisibility
    }, _createSlots({
      summary: _withCtx(() => [
        _createVNode($setup["ResourceSummary"], {
          "download-count": $props.modelDetail.downloads,
          "namespace-path": $props.modelDetail.path,
          resource: "models"
        }, null, 8 /* PROPS */, ["download-count", "namespace-path"])
      ]),
      community: _withCtx(() => [
        _createVNode($setup["CommunityPage"], {
          type: "Model",
          localModelId: $props.localModelId
        }, null, 8 /* PROPS */, ["localModelId"])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      ($props.actionName === 'blob')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["ResourceBlob"], {
                resource: "model",
                content: $setup.decodedContent,
                "last-commit": $props.blob.commit,
                branches: $props.branches,
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "namespace-path": $props.modelDetail.path,
                size: $props.blob.size,
                "can-write": $props.canWrite
              }, null, 8 /* PROPS */, ["content", "last-commit", "branches", "current-branch", "current-path", "namespace-path", "size", "can-write"])
            ]),
            key: "0"
          }
        : undefined,
      ($props.actionName === 'new_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["NewFile"], {
                "current-branch": $props.currentBranch,
                "repo-name": $props.modelDetail.name,
                "namespace-path": $props.modelDetail.path,
                originalCodeContent: ""
              }, null, 8 /* PROPS */, ["current-branch", "repo-name", "namespace-path"])
            ]),
            key: "1"
          }
        : undefined,
      ($props.actionName === 'edit_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["EditFile"], {
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "repo-name": $props.modelDetail.name,
                "namespace-path": $props.modelDetail.path,
                originalCodeContent: $setup.decodedContent,
                sha: $props.blob.sha
              }, null, 8 /* PROPS */, ["current-branch", "current-path", "repo-name", "namespace-path", "originalCodeContent", "sha"])
            ]),
            key: "2"
          }
        : undefined,
      ($props.actionName === 'upload_file')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["UploadFile"], {
                "current-branch": $props.currentBranch,
                "repo-name": $props.modelDetail.name,
                "namespace-path": $props.modelDetail.path
              }, null, 8 /* PROPS */, ["current-branch", "repo-name", "namespace-path"])
            ]),
            key: "3"
          }
        : undefined,
      ($props.actionName === 'show' || $props.actionName === 'files')
        ? {
            name: "files",
            fn: _withCtx(() => [
              _createVNode($setup["ModelFiles"], {
                branches: $props.branches,
                "current-branch": $props.currentBranch,
                "current-path": $props.currentPath,
                "namespace-path": $props.modelDetail.path,
                "can-write": $props.canWrite
              }, null, 8 /* PROPS */, ["branches", "current-branch", "current-path", "namespace-path", "can-write"])
            ]),
            key: "4"
          }
        : undefined,
      ($props.settingsVisibility)
        ? {
            name: "settings",
            fn: _withCtx(() => [
              _createVNode($setup["Settings"], {
                path: $props.modelPath,
                "model-nickname": $props.modelNickname,
                "model-desc": $props.modelDesc,
                default_branch: $props.modelDefaultBranch,
                private: $props.modelPrivate
              }, null, 8 /* PROPS */, ["path", "model-nickname", "model-desc", "default_branch", "private"])
            ]),
            key: "5"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["default-tab", "settingsVisibility"])
  ]))
}