<template>
  <div class="bg-gradient-to-l from-[#FAFAFA] to-white">
    <div class="mx-auto max-w-[1440px] pt-[16px] px-[80px] pb-[40px] md:p-[16px]">
      <breadcrumb 
        referActionUrl="/models"
        referActionName="模型列表"
        currentActionName="模型详情"
      />
      <div class="mt-[10px]">
        <resource-header
          :private="model.data.private"
          :license="model.data.license"
          :name="model.data.name"
          :nickname="model.data.nickname"
          :desc="model.data.description"
          :path="model.data.path"
          :tags="tags"
          :avatar="avatar"
          :resource="model.data"
          :owner-url="ownerUrl"
        />
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-[1440px] px-[80px] mt-[-40px] md:px-[16px]">
    <model-tabs
      :blob="blob.data"
      :local-model-id="localModelId"
      :local-model="localModel"
      :model-path="model.data.path"
      :model-nickname="localModel.nickname"
      :model-desc="localModel.desc"
      :model-default-branch="model.data.default_branch"
      :model-private="model.data.private"
      :model-detail="model.data"
      :last-commit="lastCommit.data"
      :branches="branches.data"
      :current-branch="currentBranch"
      :current-path="currentPath"
      :default-tab="defaultTab"
      :actionName="actionName"
      :settingsVisibility="settingsVisibility"
      :can-write="canWrite"
    />
  </div>
</template>

<script setup>
import ResourceHeader from '../shared/ResourceHeader.vue'
import Breadcrumb from '../Breadcrumb.vue'
import ModelTabs from './ModelTabs.vue'

const props = defineProps({
  localModelId: String,
  defaultTab: String,
  model: Object,
  localModel: Object,
  lastCommit: Object,
  branches: Object,
  tags: Object,
  currentBranch: String,
  currentPath: String,
  blob: Object,
  actionName: String,
  settingsVisibility: Boolean,
  avatar: String,
  ownerUrl: String,
  canWrite: Boolean
});
</script>

