import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex flex-col gap-[8px] rounded-[4px] w-full p-[16px] border-[1px] border-[#E6E6E6] shadow-[0_-1px_0_0_rgba(0,0,0,0.05)_inset]" }
const _hoisted_3 = { class: "flex gap-[8px]" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  src: "/images/xty_images/header/flag/blue_v.svg",
  class: "absolute h-[16px] w-[16px] right-[0px] bottom-[1.5px] object-cover",
  alt: ""
}
const _hoisted_7 = { class: "flex flex-col gap-[8px]" }
const _hoisted_8 = { class: "flex gap-[8px] items-center" }
const _hoisted_9 = { class: "text-[18px] font-[500] leading-[20px] text-[#000000E0] line-clamp-1" }
const _hoisted_10 = { class: "border-[1px] border-[#D0D5DD] rounded-[6px] px-[8px] py-[3px] text-[#344054] text-[12px] font-[400] leading-[18px]" }
const _hoisted_11 = { class: "flex gap-[8px] items-center" }
const _hoisted_12 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467] line-clamp-2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    class: "flex max-w-[510px] hover:shadow transition-all",
    href: `/organizations/${$props.item.name}`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: $props.item.avatar,
            alt: "",
            class: "w-[60px] h-[60px] rounded-full object-cover"
          }, null, 8 /* PROPS */, _hoisted_5),
          ($props.item.verified)
            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString($props.item.nickname || $props.item.name), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_10, _toDisplayString($props.item.org_type), 1 /* TEXT */)
          ]),
          _createCommentVNode(" 行业标签 "),
          _createElementVNode("div", _hoisted_11, [
            _createCommentVNode(" industry_list "),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.industries, (industry) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "text-[12px] opacity-[80] px-[8px] py-[3px] rounded-[4px] border",
                key: industry
              }, _toDisplayString(industry), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, _toDisplayString($props.item.description), 1 /* TEXT */)
    ])
  ], 8 /* PROPS */, _hoisted_1))
}