<template>
  <div class="relative">
    <ResourceClone
      resource="model"
      :bt-link="localModel.bt_link"
      :http-clone-url="modelDetail.repository.http_clone_url"
      :ssh-clone-url="modelDetail.repository.ssh_clone_url" />
    <TabContainer resource="model" :default-tab="defaultTab" :settingsVisibility="settingsVisibility">
      <template #summary>
        <resource-summary 
          :download-count="modelDetail.downloads" 
          :namespace-path="modelDetail.path" 
          resource="models"
        />
      </template>
      <template #files v-if="actionName === 'blob'">
        <resource-blob
          resource="model"
          :content="decodedContent"
          :last-commit="blob.commit"
          :branches="branches"
          :current-branch="currentBranch"
          :current-path="currentPath"
          :namespace-path="modelDetail.path"
          :size="blob.size"
          :can-write="canWrite"
        />
      </template>
      <template #files v-if="actionName === 'new_file'">
        <new-file
          :current-branch="currentBranch"
          :repo-name="modelDetail.name"
          :namespace-path="modelDetail.path"
          originalCodeContent=""
        />
      </template>
      <template #files v-if="actionName === 'edit_file'">
        <edit-file
          :current-branch="currentBranch"
          :current-path="currentPath"
          :repo-name="modelDetail.name"
          :namespace-path="modelDetail.path"
          :originalCodeContent="decodedContent"
          :sha="blob.sha"
        />
      </template>
      <template #files v-if="actionName === 'upload_file'">
        <upload-file
          :current-branch="currentBranch"
          :repo-name="modelDetail.name"
          :namespace-path="modelDetail.path"
        />
      </template>
      <template #files v-if="actionName === 'show' || actionName === 'files'">
        <model-files
          :branches="branches"
          :current-branch="currentBranch"
          :current-path="currentPath"
          :namespace-path="modelDetail.path"
          :can-write="canWrite"
        />
      </template>
      <template #community>
        <CommunityPage type="Model" :localModelId="localModelId" ></CommunityPage>
      </template>
      <template v-if="settingsVisibility" #settings>
        <Settings
          :path="modelPath"
          :model-nickname="modelNickname"
          :model-desc="modelDesc"
          :default_branch="modelDefaultBranch"
          :private="modelPrivate" />
      </template>
    </TabContainer>
  </div>
</template>

<style>
  .clone-tabs .el-tabs__header {
    padding-left: 12px;
  }
</style>

<script setup>
import ResourceClone from '../shared/ResourceClone.vue'
import TabContainer from '../shared/TabContainer.vue'
import ResourceSummary from '../shared/ResourceSummary.vue'
import ModelFiles from './ModelFiles.vue'
import CommunityPage from '../community/CommunityPage.vue'
import Settings from './ModelSettings.vue'
import ResourceBlob from '../shared/ResourceBlob.vue'
import UploadFile from '../shared/UploadFile.vue'
import NewFile from '../shared/NewFile.vue'
import EditFile from '../shared/EditFile.vue'


const props = defineProps({
  localModelId: String,
  localModel: Object,
  modelPath: String,
  modelNickname: String,
  modelDesc: String,
  modelDefaultBranch: String,
  modelPrivate: Boolean,
  modelDetail: Object,
  lastCommit: Object,
  branches: Object,
  currentBranch: String,
  currentPath: String,
  defaultTab: String,
  blob: Object,
  actionName: String,
  settingsVisibility: Boolean,
  canWrite: Boolean,
})
const decodedContent = props.blob?.content || ''
</script>
