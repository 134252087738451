<template>
    <div>
      <div class="w-[294px] rounded-[8px] mx-[24px]">
        <div class="flex p-[16px]">
          <el-avatar :size="60" :src="avatar"> </el-avatar>
          <div class="ml-[10px]">
            <div class="text-[24px] leading-[32px] font-semibold">
              <a :href="'/profile/' + userInfo.login_identity">
                {{displayName}}
              </a>
            </div>
            <div class="text-[16px] text-[#909399] leading-[24px]">@{{name}}</div>
          </div>
        </div>
        <div class="text-[20px] font-[600] leading-[28px] p-[16px]">
          设置
        </div>
        <div class="flex flex-col">
          <a href="/settings/profile" class="p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] cursor-pointer"
               :class="menuClass('/settings/profile')"
          >
              个人资料
          </a>
          <!-- <div class="p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] opacity-40"
               :class="menuClass('/settings/account')"
          >
            账户信息
          </div>
          <div class="p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] opacity-40"
               :class="menuClass('/settings/accessTokens')"
          >
            Access Token
          </div> -->
          <a href="/settings/git-token" class="p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] cursor-pointer"
               :class="menuClass('/settings/git-token')"
          >
              Git Token
          </a>
          <a href="/settings/ssh-keys" class="p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] cursor-pointer"
               :class="menuClass('/settings/ssh-keys')"
          >
              SSH Keys
          </a>
          <!-- <div class="p-[12px] hover:bg-[#EBEDF0] text-[16px] text-[#344054] leading-[26px] opacity-40"
               :class="menuClass('/settings/billing')"
          >
            账单
          </div> -->
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    name: String,
    displayName: String,
    avatar: String,
    userInfo: Object,
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.userInfo);
  },
  methods: {
    menuClass(menuPath) {
      if (menuPath === window.location.pathname) {
        return 'text-[#303133] font-semibold'
      } else {
        return ''
      }
    }
  }
};
</script>
