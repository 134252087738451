<template>
  <div class="w-full md:border-0 community-border-b">
    <div class="max-w-[1440px] md:max-w-full m-auto flex md:justify-between justify-start items-center md:h-[44px] h-[56px] md:px-[16px] px-[32px] md:gap-[0px] gap-[16px] relative w-full">
      <div class="flex">
        <div>
          <a href="/">
            <img :src="logo.header_logo" alt="Community Logo" class="h-[32px]" />
          </a>
        </div>
      </div>
      <menusV2 :menu="menu" />
      <div class="flex items-center">
        <el-dropdown v-if="isLoggedInBoolean" class="pl-1">
          <span v-if="JSON.parse(companyVerified.toLowerCase())" class="el-dropdown-link relative">
            <el-avatar :size="26" :src="avatar">
            </el-avatar>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="absolute bottom-0 right-0">
              <rect x="0.5" y="0.5" width="14" height="14" rx="7" fill="white"/>
              <path d="M7.5 1C3.88208 1 1 3.88208 1 7.5C1 11.1179 3.88208 14 7.5 14C11.1179 14 14 11.1179 14 7.5C14 3.88208 11.1179 1 7.5 1ZM11.3019 11.3632H3.57547V10.5047H4.92453V7.00943H5.78302V10.5047H7.31604V5.66038H8.17453V7.62264H10.6274V8.48113H8.17453V10.5047H11.3019V11.3632ZM11.3632 7.00943C9.89151 6.21226 8.60377 5.23113 7.5 3.9434C6.51887 5.10849 5.23113 6.15094 3.63679 7.00943L3.14623 6.15094C4.74057 5.35377 6.08962 4.37264 7.1934 3.14623H7.86792C9.03302 4.43396 10.3821 5.41509 11.8538 6.15094L11.3632 7.00943Z" fill="#67C23A"/>
              <rect x="0.5" y="0.5" width="14" height="14" rx="7" stroke="white"/>
            </svg>
          </span>
          <span v-else-if="JSON.parse(isCompanyUser.toLowerCase())" class="el-dropdown-link relative">
            <el-avatar :size="26" :src="avatar">
            </el-avatar>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="absolute bottom-0 right-0">
              <rect x="0.5" y="0.5" width="14" height="14" rx="7" fill="black" fill-opacity="0.8"/>
              <path d="M7.5 1C3.88208 1 1 3.88208 1 7.5C1 11.1179 3.88208 14 7.5 14C11.1179 14 14 11.1179 14 7.5C14 3.88208 11.1179 1 7.5 1ZM11.3019 11.3632H3.57547V10.5047H4.92453V7.00943H5.78302V10.5047H7.31604V5.66038H8.17453V7.62264H10.6274V8.48113H8.17453V10.5047H11.3019V11.3632ZM11.3632 7.00943C9.89151 6.21226 8.60377 5.23113 7.5 3.9434C6.51887 5.10849 5.23113 6.15094 3.63679 7.00943L3.14623 6.15094C4.74057 5.35377 6.08962 4.37264 7.1934 3.14623H7.86792C9.03302 4.43396 10.3821 5.41509 11.8538 6.15094L11.3632 7.00943Z" fill="white"/>
              <rect x="0.5" y="0.5" width="14" height="14" rx="7" stroke="#DCDFE6"/>
            </svg>
          </span>
          <span v-else class="el-dropdown-link">
            <UserAvatar typo="sm" :avatar="avatar" :flag="userInfo.flag" />
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <a :href="userProfile">
                <el-dropdown-item> 个人信息 </el-dropdown-item>
              </a>
              <a href="/settings/profile">
                <el-dropdown-item> 账号设置 </el-dropdown-item>
              </a>
              <!-- <el-dropdown-item @click="showDialog" > 联系我们 </el-dropdown-item> -->
              <a href="/models/new">
                <el-dropdown-item divided> + 新建模型 </el-dropdown-item>
              </a>
              <a href="/datasets/new">
                <el-dropdown-item> + 新建数据集 </el-dropdown-item>
              </a>
              <a href="/organizations/new">
                <el-dropdown-item divided> 新建组织 </el-dropdown-item>
              </a>
              <a href="/daily_papers/new" v-if="canCreateDailyPaper">
                <el-dropdown-item> 论文推荐 </el-dropdown-item>
              </a>
              <a href="/logout">
                <el-dropdown-item divided>  退出登录 </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <button v-else class="min-w-[72px] sm:min-w-[46px] btn-orange rounded-[8px] px-[12px] py-[5px] flex items-center justify-center text-[12px] font-500 leading-[20px]">
          <a class="sm:hidden" href="/login">登录</a>
          <a class="hidden sm:block" href="/login">
            登录
          </a>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import MobileMenusIcon from "../icons/MobileMenusIcon.vue";
  import CloseMenusIcon from "../icons/CloseMenusIcon.vue";
  import menusV2 from "./menusV2.vue";
  import UserAvatar from "./userAvatar.vue";
  import { ref } from 'vue';

  const props = defineProps({
    logout: String,
    avatar: String,
    starChainUrl: String,
    isCompanyUser: String,
    companyVerified: String,
    phone: String,
    isLoggedIn: String,
    userName: String,
    userId: String,
    canCreateDailyPaper: Boolean,
    menu: Object,
    logo: Object,
    userInfo: Object,
  });

  const classParam = new URLSearchParams(window.location.search).get('class');
  const csgHubUrl = 'https://github.com/OpenCSGs/CSGHub'
  const activeIndex = classParam ?  classParam : window.location.pathname
  const isLoggedInBoolean = JSON.parse(props.isLoggedIn.toLowerCase())
  const userProfile = `/profile/${props.userId}`


  const controllersMap = {
    "community_frontend/home": {
      name: "首页",
    },
    "community_frontend/rank_configurations": {
      name: "排行榜",
    },
    "community_frontend/models": {
      name: "模型库",
    },
    "community_frontend/datasets": {
      name: "数据集",
    },
    "community_frontend/spaces": {
      name: "应用空间",
    },
    "community_frontend/daily_papers": {
      name: "文章推荐",
    },
  };
  const mobileMenusVisible = ref(false);

</script>

