<template>
  <a class="flex max-w-[510px] hover:shadow transition-all" :href="`/organizations/${item.name}`">
    <div class="flex flex-col gap-[8px] rounded-[4px] w-full p-[16px] border-[1px] border-[#E6E6E6] shadow-[0_-1px_0_0_rgba(0,0,0,0.05)_inset]">
      <div class="flex gap-[8px]">
        <div class="relative">
          <img :src="item.avatar" alt="" class="w-[60px] h-[60px] rounded-full object-cover">
          <template v-if="item.verified">
            <img src="/images/xty_images/header/flag/blue_v.svg" class="absolute h-[16px] w-[16px] right-[0px] bottom-[1.5px] object-cover" alt="">
          </template>
        </div>
        <div class="flex flex-col gap-[8px]">
  
          <div class="flex gap-[8px] items-center">
            <div class="text-[18px] font-[500] leading-[20px] text-[#000000E0] line-clamp-1">
              {{ item.nickname || item.name }}
            </div>
            <div class="border-[1px] border-[#D0D5DD] rounded-[6px] px-[8px] py-[3px] text-[#344054] text-[12px] font-[400] leading-[18px]">
              {{ item.org_type }}
            </div>
          </div>
  
          <!-- 行业标签 -->
          <div class="flex gap-[8px] items-center">
            <!-- industry_list -->
            <div class="text-[12px] opacity-[80] px-[8px] py-[3px] rounded-[4px] border" v-for="industry in item.industries" :key="industry">
              {{ industry }}
            </div>
          </div>
        </div>
      </div>
      <div class="text-[14px] font-[400] leading-[20px] text-[#475467] line-clamp-2">
        {{ item.description }}
      </div>
  
    </div>
  </a>
</template>
<script>
  export default {
    props: {
      item: {
        type: Object,
      },
    },
  }
</script>